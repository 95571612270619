import { jwtDecode } from 'jwt-decode';
import { push } from 'connected-react-router';
import * as myAccountActions from 'constants/action-types';
import {
  ADMIN,
  AGREEMENT,
  BUSINESS,
  CANCEL,
  E_AND_O,
  FILE,
  FIRM,
  INDIVIDUAL,
  INVALID_FILE_TYPE,
  IS_PRIMARY,
  LICENSE_AND_ENO,
  PRINCIPAL_ACCESS_ADDED,
  PRINCIPAL_ACCESS_ROLE,
  PRODUCER_ACCESS_ADDED,
  TAX_KEY,
  TOTAL_MEMBER_ADDED,
  W9,
} from 'constants/constant';
import {
  filter,
  find,
  findIndex,
  get,
  isArray,
  isEmpty,
  isUndefined,
  remove,
} from 'lodash';
import * as moment from 'moment';
import { displayErrorMessage, displaySuccessMessage } from 'util/user-feedback';
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import {
  fetchPolicyPremiumDetails,
  storePolicyPremiumDetails,
} from 'redux/actions/dashboard';
import axios from 'util/api';
import {
  formatNumber,
  getCurrentFinancialYear,
  getModulePermission,
  globalMessages,
  uploadFile,
} from 'util/extra';
import {
  licenseFileLoader,
  licenseListLoader,
  setAgentStatusLoaderFun,
  setCompleteAgentProfile,
  storeNewSubAgentDetails,
  updateAgentLoader,
  updateLicenseList,
  updateStoredSettings,
} from '../actions/agent-details';
import { updateComplianceStatus } from '../actions/auth';
import { setCarrierListLoader } from '../actions/carrier-list';
import { errorHandler } from '../actions/error';
import {
  agentDocumentFilesTreeUpdate,
  agentDocumentFilesUploadDone,
  agentDocumentFilesUploading,
  agentDocumentFileUploadError,
  clearDirectoryList,
  clearMyAccountData,
  deleteDocumentDetails,
  fetchAgentDocumentDetails,
  invalidFileType,
  setAgentDetails,
  setAgentDetailsLoader,
  setAgentYTDStats,
  setValidateNiprResponse,
  showReauthenticateModal,
  storeAgentDetails,
  storeAgreementData,
  storedDocumentLoader,
  storeDocumentDetails,
  storeSubAgentStatus,
  teamMemberAdded,
  teamMemberDetailsLoader,
  teamMemberDuplicateEmailHandling,
  updateLicenseOnRefresh,
  updateLicenseOnRefreshLoader,
} from '../actions/my-account';
import config from 'config';
import { ADD_TEAM_MEMBER_MODAL } from 'constants/heap-events';
import { getImpersonateBFFRequestConfig } from './impersonate';
import { getToken } from 'util/auth/get-token';

function* checkStepUpNecessitySaga() {
  try {
    const token = yield call(getToken);
    const decodedToken = jwtDecode(token);

    if (decodedToken.step_up_access !== true) {
      yield put(showReauthenticateModal());
    }
  } catch (err) {
    yield put(errorHandler(err));
  }
}

export function* checkStepUpNecessityWatcher() {
  yield takeEvery(
    myAccountActions.CHECK_STEP_UP_NECESSITY,
    checkStepUpNecessitySaga
  );
}

function* fetchAgentDetails({ payload }) {
  const { producer_id } = payload;
  const { auth } = yield select();
  const { userRole } = auth;
  let isImpersonateModeOn = localStorage.getItem('isImpersonatedDataPresent');
  try {
    yield put(storeDocumentDetails([]));
    yield put(clearDirectoryList());
    yield put(setAgentDetailsLoader(true));
    yield put(
      storePolicyPremiumDetails({
        policies_count_by_carrier: {},
        premium_report: {},
        axisDetail: {},
      })
    );
    const userDetails = yield axios.get(
      `api/v1/producer/get/agent/${producer_id}`
    );
    yield put(clearMyAccountData({}));
    if (userDetails.data) {
      let details = userDetails.data;
      details.producers.agencyProfile = {
        ...details.producers.agencyProfile,
        created_at: details.producers.created_at,
        pendingAppointmentRequestsCount:
          details?.producers?.pending_appointment_requests_count || 0,
        reviewAppointmentRequestsCount:
          details?.producers?.review_appointment_requests_count || 0,
      };
      yield put(setAgentDetails(details));
      yield put(
        fetchAgentDocumentDetails(
          `${config.PRODUCER_UPLOAD_FOLDER}/${details.producers.producer_code}`
        )
      );
      yield put(fetchPolicyPremiumDetails(producer_id));
    }
  } catch (error) {
    yield put(errorHandler(error));
    if (errorHandler(error)?.payload?.response?.status === 400) {
      yield put(push(`/app/${userRole}/error`));
      window?.location?.reload();
    }
  }

  let ytdUpdateDetails = {
    totalCommissionYTD: 0,
    totalIncentivesYTD: 0,
    totalPayoutYTD: 0,
  };

  const { impersonate, modulePermissions } = yield select();
  const { permissions, isImpersonate } = impersonate;
  let modulePermissionsData = getModulePermission(
    modulePermissions.modulePermissionsData,
    permissions,
    isImpersonate
  );

  const { 'COMMISSION-INCENTIVE-YTD': COMMISSION_INCENTIVE_YTD } =
    !!modulePermissionsData && modulePermissionsData['MY-ACCOUNT'];

  if (COMMISSION_INCENTIVE_YTD && COMMISSION_INCENTIVE_YTD.read) {
    try {
      const ytdDetails = yield axios.get(
        `api/v1/producer/agent-commission-incentive/${producer_id}`
      );
      if (ytdDetails.data) {
        const ytdMonthList = [...ytdDetails.data.agent_commission_report];

        filter(ytdMonthList, (data) => {
          let start = moment(getCurrentFinancialYear(true).start, 'MMMM YYYY');
          let end = moment(getCurrentFinancialYear(true).end, 'MMMM YYYY');

          // determines whether date falls in fiscal year or not.
          if (start && end) {
            ytdUpdateDetails = {
              totalCommissionYTD:
                ytdUpdateDetails.totalCommissionYTD + data.commission,
              totalIncentivesYTD:
                ytdUpdateDetails.totalIncentivesYTD + data.incentive,
              totalPayoutYTD: ytdUpdateDetails.totalPayoutYTD + data.total,
            };
          }
        });
      }
    } catch (error) {
      ytdUpdateDetails = {
        totalCommissionYTD: 0,
        totalIncentivesYTD: 0,
        totalPayoutYTD: 0,
      };
    }

    // updating values with $ values.
    ytdUpdateDetails = {
      totalCommissionYTD: formatNumber(ytdUpdateDetails.totalCommissionYTD, 0),
      totalIncentivesYTD: formatNumber(ytdUpdateDetails.totalIncentivesYTD, 0),
      totalPayoutYTD: formatNumber(ytdUpdateDetails.totalPayoutYTD, 0),
    };

    // YTD stats for commissions, incentives and total payout.
    yield put(setAgentYTDStats(ytdUpdateDetails));
  }
  yield put(
    setCompleteAgentProfile({
      agentId: '',
      agentCode: '',
      redirectMyAccount: false,
    })
  );
  yield put(setAgentDetailsLoader(false));
  if (!!isImpersonateModeOn) {
    window.resetActiveMenuItem = false;
  }
}

export function* fetchAgentDetailsWatcher() {
  yield takeEvery(myAccountActions.FETCH_AGENT_DETAILS, fetchAgentDetails);
}

function* saveSettingsDetails({ payload: { newData, ...rest } }) {
  yield put(setCarrierListLoader(true));
  try {
    const { myAccount, carriers } = yield select();
    const selectedCarrier = find(
      carriers.carrierList,
      (carrier) => carrier.display_name === newData.name
    );

    const details = yield axios.post(
      '/api/v1/commission/store-commission-payment-setting',
      {
        carrier_id: selectedCarrier.id,
        producer_id: myAccount.details.producers.producer_id,
        incentive_paid_to: newData.incentive,
        commission_paid_to: newData.commission,
        carrier_producer_code: newData.mapping,
        appointed: newData.appointed,
        carrier_agency_code: newData.agencyCode,
      }
    );
    if (details.data) {
      // update internal store for client.
      yield put(updateStoredSettings({ newData, ...rest }));
      yield displaySuccessMessage(details.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(setCarrierListLoader(false));
}

export function* saveSettingsWatcher() {
  yield takeEvery(myAccountActions.SAVE_SETTINGS, saveSettingsDetails);
}

// save / update and add  License List
function* saveLicenseListDetails({
  payload: { newData, file, type, ...rest },
}) {
  try {
    yield put(licenseListLoader(true));
    const { auth, myAccount } = yield select();
    let uploadFileUrl = type === CANCEL ? file : '';

    if (file && isEmpty(uploadFileUrl)) {
      const fileData = file[0];
      const file_name = fileData.name;
      const folder_name = myAccount.details.producers.producer_code;
      const fileURL = `${config.PRODUCER_UPLOAD_FOLDER}/${folder_name}/licenses/${file_name}`;
      const result = yield call(uploadFile, file[0], fileURL);
      if (result && result.status === 200) {
        const { file: fileURLData } = result.data;
        uploadFileUrl = fileURLData;
      } else {
        yield put(errorHandler(result));
      }
    }
    const licenseDataResponse = yield axios.post(
      '/api/v1/producer/add-license',
      {
        producer_id: myAccount.details.producers.producer_id,
        licenseData: {
          state: newData.stateData,
          status: newData.status,
          expiryDate: !newData.dne ? newData.date : null,
          licenseNumber: newData.license,
          loa: newData.loa,
          licenseType: newData.licenseType,
          isPrimary: get(newData, IS_PRIMARY, false),
          licenseClass: newData.licenseClass,
          fileUrl: uploadFileUrl,
        },
      }
    );

    if (newData.licenseType.toLowerCase() === BUSINESS) {
      newData.licenseType = FIRM;
    } else if (newData.licenseType.toLowerCase() === INDIVIDUAL.toLowerCase()) {
      newData.licenseType = INDIVIDUAL.toUpperCase();
    }
    if (!!licenseDataResponse?.data) {
      let responseData = licenseDataResponse.data;
      let licenseComplianceStatus = '';
      if (
        !isEmpty(responseData.complianceStatus) &&
        !isUndefined(responseData.complianceStatus)
      ) {
        const { license } = responseData.complianceStatus;
        licenseComplianceStatus = license;
      }
      newData.uuid = responseData.data.uuid;
      newData.licenseClass = responseData.data.licenseClass;
      yield put(
        updateLicenseList({
          licenseComplianceStatus,
          newData: {
            ...newData,
            updatedByProducerName: `${auth.loginName} ${auth.userLastName}`,
            fromNIPR: responseData.data.fromNIPR,
            updatedAt: responseData.data.updatedAt,
          },
          uploadFileUrl: responseData.data.fileUrl,
          ...rest,
        })
      );
      let complianceStatus = {
        eno: '',
        license: '',
        w9: '',
      };
      if (!!auth?.complianceInfo) {
        const { complianceInfo, userRole } = auth;
        complianceStatus = {
          eno: complianceInfo.eno_status,
          license: licenseComplianceStatus,
          w9: complianceInfo.w9_status,
        };
        if (userRole !== ADMIN) {
          yield put(updateComplianceStatus(complianceStatus));
        }
      }
      yield displaySuccessMessage(licenseDataResponse.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(licenseListLoader(false));
}

export function* saveLicenseListWatcher() {
  yield takeEvery(myAccountActions.SAVE_LICENSE_LIST, saveLicenseListDetails);
}

// update  License List
function* saveUpdateLicenseListDetails({
  payload: { newData, file, type, resolve, reject, ...rest },
}) {
  try {
    let fileTypeError = null;
    const { auth, myAccount } = yield select();
    let uploadFileUrl = type === CANCEL ? file : '';
    let isErrorPresent = false;
    yield put(invalidFileType(fileTypeError));
    if (file && isEmpty(uploadFileUrl)) {
      yield put(licenseFileLoader(true));
      const fileData = file[0];
      const file_name = fileData.name;
      const folder_name = myAccount.details.producers.producer_code;
      const fileURL = `${config.PRODUCER_UPLOAD_FOLDER}/${folder_name}/licenses/${newData.uuid}/${file_name}`;
      const result = yield call(uploadFile, file[0], fileURL);
      if (result && result.status === 200) {
        fileTypeError = null;
        const { file: fileURLData } = result.data;
        uploadFileUrl = fileURLData;
        resolve();
      } else {
        fileTypeError = INVALID_FILE_TYPE;
        reject();
        isErrorPresent = true;
        yield put(errorHandler(result));
      }
      yield put(invalidFileType(fileTypeError));
      yield put(licenseFileLoader(false));
    }
    if (!isErrorPresent) {
      yield put(licenseListLoader(true));
      const licenseDataResponse = yield axios.post(
        '/api/v1/producer/update-license',
        {
          producer_id: myAccount.details.producers.producer_id,
          licenseData: {
            state: newData.stateData,
            status: newData.status,
            expiryDate: !newData.dne ? newData.date : null,
            licenseNumber: newData.license,
            loa: newData.loa,
            licenseType: newData.licenseType,
            isPrimary: get(newData, IS_PRIMARY, false),
            uuid: newData.uuid,
            licenseClass: newData.licenseClass,
            fileUrl: uploadFileUrl,
          },
        }
      );

      if (newData.licenseType.toLowerCase() === BUSINESS) {
        newData.licenseType = FIRM;
      } else if (
        newData.licenseType.toLowerCase() === INDIVIDUAL.toLowerCase()
      ) {
        newData.licenseType = INDIVIDUAL.toUpperCase();
      }
      if (!!licenseDataResponse?.data) {
        let licenseComplianceStatus = '';
        let responseData = licenseDataResponse.data;
        if (
          !isEmpty(responseData.complianceStatus) &&
          !isUndefined(responseData.complianceStatus)
        ) {
          const { license } = responseData.complianceStatus;
          licenseComplianceStatus = license;
        }
        let complianceStatus = {
          eno: '',
          license: '',
          w9: '',
        };
        if (!!auth?.complianceInfo) {
          const { complianceInfo, userRole } = auth;
          complianceStatus = {
            eno: complianceInfo.eno_status,
            license: licenseComplianceStatus,
            w9: complianceInfo.w9_status,
          };
          if (userRole !== ADMIN) {
            yield put(updateComplianceStatus(complianceStatus));
          }
        }
        yield put(
          updateLicenseList({
            licenseComplianceStatus,
            newData: {
              ...newData,
              updatedByProducerName: `${auth.loginName} ${auth.userLastName}`,
              fromNIPR: newData.fromNIPR,
              updatedAt: newData.updatedAt,
            },
            uploadFileUrl,
            activate: responseData.activate,
            ...rest,
          })
        );
        yield displaySuccessMessage(licenseDataResponse.data.message);
      }
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(licenseListLoader(false));
}

export function* saveUpdateLicenseListWatcher() {
  yield takeEvery(
    myAccountActions.SAVE_UPDATE_LICENSE_LIST,
    saveUpdateLicenseListDetails
  );
}

function* addNewTeamMembers({ payload }) {
  let response = {
    teamMembers: [{ email: '', role: '' }],
  };
  try {
    yield put(teamMemberDetailsLoader(true));
    const requestConfig = yield getImpersonateBFFRequestConfig();
    let principalAccessRoles = payload.teamMembers.filter((user) => {
      return user.role === PRINCIPAL_ACCESS_ROLE;
    });
    const details = yield axios.post(
      `${config.BFF_URL}/api/v1/producer/team-member/add`,
      payload,
      requestConfig
    );
    if (details.data.success) {
      window.heap.track(ADD_TEAM_MEMBER_MODAL.CLICK_CTA, {
        [TOTAL_MEMBER_ADDED]: payload?.teamMembers?.length,
        [PRODUCER_ACCESS_ADDED]:
          payload?.teamMembers?.length - principalAccessRoles?.length,
        [PRINCIPAL_ACCESS_ADDED]: principalAccessRoles?.length,
      });
      response.success = true;
      yield put(teamMemberDuplicateEmailHandling(response));
      for (let i = 0; i < details.data.teamMembers?.length; i++) {
        yield put(
          storeNewSubAgentDetails({
            ...details.data.teamMembers[i],
          })
        );
      }
      yield put(teamMemberAdded(true));
    } else {
      yield put(teamMemberDuplicateEmailHandling(details.data.teamMembers));
    }
  } catch (error) {
    yield put(teamMemberDetailsLoader(false));
    yield put(errorHandler(error));
  }
  yield put(teamMemberDetailsLoader(false));
}

export function* addNewTeamMembersWatcher() {
  yield takeEvery(myAccountActions.ADD_NEW_TEAM_MEMBERS, addNewTeamMembers);
}

function* agentDocumentDetailsfetch({ payload }) {
  try {
    yield put(storedDocumentLoader(true));
    const details = yield axios.post('/api/v1/vendor/s3-folder-types', {
      folder_name: payload.toString(),
    });

    if (details.data) {
      let updatedPayload = Object.assign({}, details.data, {
        directoryName: isArray(payload) ? payload[0] : payload,
      });
      yield put(storeDocumentDetails(updatedPayload));
    }
  } catch (error) {
    yield put(storeDocumentDetails([]));
  }
  yield put(storedDocumentLoader(false));
}

export function* fetchAgentDocumentDetailsWatcher() {
  yield takeEvery(
    myAccountActions.FETCH_AGENT_DOCUMENTS_DETAILS,
    agentDocumentDetailsfetch
  );
}

function* agentDocumentRenameAction({ payload }) {
  try {
    yield put(storedDocumentLoader(true));
    const details = yield axios.post('/api/v1/vendor/rename-s3-file', {
      ...payload,
    });
    if (details.data) {
      const { myAccount } = yield select();
      const fileList = [...myAccount[payload.folder_name].documentFilesDetails];
      const fileKey = payload.folder_name + '/' + payload.file_name;
      findIndex(fileList, function (o) {
        if (o.Key === fileKey) {
          o.Key = payload.folder_name + '/' + payload.new_file_name;
          return o;
        }
      });
      yield put(
        deleteDocumentDetails({
          updatedArray: fileList,
          folder_name: payload.folder_name,
        })
      );

      const { module } = details.data;
      const { producers } = myAccount.details;
      const { licenseAndEnO, tax } = producers;

      if (module.toLowerCase() === W9 || module.toLowerCase() === E_AND_O) {
        const { file_url, file_name } = details.data;
        let data =
          module.toLowerCase() === E_AND_O
            ? licenseAndEnO
            : module.toLowerCase() === W9
            ? tax
            : '';
        if (module.toLowerCase() === E_AND_O) {
          data = {
            ...data,
            fileurl: file_url,
            fileName: file_name,
          };
        }
        if (module.toLowerCase() === W9) {
          data = {
            ...data,
            fileurl: file_url,
            fileName: file_name,
          };
        }
        if (!isEmpty(data)) {
          let objectEntity =
            module.toLowerCase() === E_AND_O
              ? LICENSE_AND_ENO
              : module.toLowerCase() === W9
              ? TAX_KEY
              : '';
          yield put(storeAgentDetails({ entity: objectEntity, data }));
        }
      }
      yield displaySuccessMessage(globalMessages.fileRenameSuccessMessage);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(storedDocumentLoader(false));
}

export function* agentDocumentRenameWatcher() {
  yield takeEvery(myAccountActions.DOCUMENT_RENAME, agentDocumentRenameAction);
}

function* agentDocumentFolderCreateAction({ payload }) {
  try {
    yield put(storedDocumentLoader(true));
    const details = yield axios.post('/api/v1/vendor/create-s3-folder', {
      ...payload,
    });
    if (details.data) {
      yield put(fetchAgentDocumentDetails(`${payload.folder_name}`));
      yield displaySuccessMessage(globalMessages.folderCreateSuccessMessage);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(storedDocumentLoader(false));
}
export function* agentDocumentFolderCreateActionWatcher() {
  yield takeEvery(
    myAccountActions.DOCUMENT_FOLDER_CREATE,
    agentDocumentFolderCreateAction
  );
}

function* agentDocumentDeleteAction({ payload }) {
  try {
    const { myAccount } = yield select();
    const fileList = [...myAccount[payload.folder_name].documentFilesDetails];
    var filteredArray = remove(fileList, function (itm) {
      return !itm.Key.includes(payload.file_name);
    });
    yield put(storedDocumentLoader(true));
    const details = yield axios.post('/api/v1/vendor/delete-s3-file', {
      file_path: payload.file_url,
      producer_id: payload.producer_id,
    });
    if (details.data) {
      yield put(
        deleteDocumentDetails({
          updatedArray: filteredArray,
          folder_name: payload.folder_name,
        })
      );

      const { module } = details.data;
      const { producers } = myAccount.details;
      const { licenseAndEnO, tax } = producers;
      if (payload?.isAgreementFile) {
        yield put(storeAgreementData(false));
      }
      if (module.toLowerCase() === W9 || module.toLowerCase() === E_AND_O) {
        const { file_url, file_name } = details.data;
        let data =
          module.toLowerCase() === E_AND_O
            ? licenseAndEnO
            : module.toLowerCase() === W9
            ? tax
            : '';

        if (module.toLowerCase() === E_AND_O) {
          data = {
            ...data,
            fileurl: file_url,
            fileName: file_name,
          };
        }
        if (module.toLowerCase() === W9) {
          data = {
            ...data,
            fileurl: file_url,
            fileName: file_name,
          };
        }
        if (!isEmpty(data)) {
          let objectEntity =
            module.toLowerCase() === E_AND_O
              ? LICENSE_AND_ENO
              : module.toLowerCase() === W9
              ? TAX_KEY
              : '';
          yield put(storeAgentDetails({ entity: objectEntity, data }));
        }
      }

      yield displaySuccessMessage(globalMessages.fileDeleteSuccessMessage);
    }
  } catch (error) {
    yield put(deleteDocumentDetails([]));
  }
  yield put(storedDocumentLoader(false));
}

export function* agentDocumentDeleteWatcher() {
  yield takeEvery(myAccountActions.DOCUMENT_DELETE, agentDocumentDeleteAction);
}

function* agentDocumentFilesUpload({ payload }) {
  try {
    const { file, folder_name, entity } = payload;
    const name = file[0].name;
    yield put(agentDocumentFilesUploading());
    yield put(storedDocumentLoader(true));
    const fileURL = `${folder_name}/${name}`;
    const result = yield call(uploadFile, file[0], fileURL);
    if (result && result.status === 200) {
      const { file: fileURLData } = result.data;
      yield put(
        agentDocumentFilesTreeUpdate({
          folder_name,
          file: {
            Key: folder_name + '/' + name,
            Size: file[0].size,
            Type: FILE,
            isAgreementFile: entity === AGREEMENT,
          },
        })
      );
      yield put(agentDocumentFilesUploadDone());
      const { myAccount } = yield select();
      const { licenseAndEnO, tax } = myAccount.details.producers;
      const responseFile = yield axios.post('/api/v1/producer/update-file', {
        producer_id: myAccount.details.producers.producer_id,
        file_path: fileURLData,
        file_category: entity,
      });
      if (responseFile.data) {
        const { file_url, file_name } = responseFile.data;
        let data =
          entity === E_AND_O
            ? !!licenseAndEnO
              ? licenseAndEnO
              : {}
            : entity === W9
            ? !!tax
              ? tax
              : {}
            : '';
        if (entity === W9 && responseFile?.data?.complianceStatus) {
          if (!isEmpty(data)) {
            data.w9_compliance_status = responseFile.data.complianceStatus.w9;
          } else {
            data.tax = {
              w9_compliance_status: responseFile.data.complianceStatus.w9,
            };
          }
        }
        if (entity === E_AND_O) {
          if (!isEmpty(data)) {
            data.eno_compliance_status = responseFile.data.complianceStatus.eno;
          } else {
            data.licenseAndEnO = {
              eno_compliance_status: responseFile.data.complianceStatus.eno,
            };
          }
        }
        if (entity === E_AND_O) {
          data = {
            ...data,
            fileurl: file_url,
            fileName: file_name,
          };
        }
        if (entity === W9) {
          data = {
            ...data,
            fileurl: file_url,
            fileName: file_name,
          };
        }
        if (!isEmpty(data)) {
          let objectEntity =
            entity === E_AND_O ? LICENSE_AND_ENO : entity === W9 ? TAX_KEY : '';
          yield put(storeAgentDetails({ entity: objectEntity, data }));
        }
        if (entity === AGREEMENT) {
          yield put(storeAgreementData(true));
        }
      }
      yield displaySuccessMessage(globalMessages.fileUploadSuccessMessage);
      yield put(agentDocumentFileUploadError(false));
    } else {
      yield put(errorHandler(result));
      yield put(agentDocumentFileUploadError(true));
    }
  } catch (error) {
    yield put(agentDocumentFilesUploadDone());
    yield put(errorHandler(error));
  }
  yield put(storedDocumentLoader(false));
}

export function* agentDocumentFilesUploadWatcher() {
  yield takeEvery(
    myAccountActions.AGENT_DOCUMENT_FILES_UPLOAD,
    agentDocumentFilesUpload
  );
}

function* enow9FileDownload({ payload }) {
  yield put(setAgentStatusLoaderFun(true));

  let details = null;

  try {
    details = yield axios.post('/api/v1/vendor/aws-config', payload);
  } catch (error) {
    // FRC-3321: Disallow for downloading W9 files for the producers
    // Currently, we expect to get the 403 error code from the server
    // when the producer tries to download the W9 file. This might
    // be changed in the future, as it's prone to errors.
    if (error.response && error.response.status === 403) {
      yield put(setAgentStatusLoaderFun(false));
      return;
    }
  }

  try {
    if (details.data) {
      const { returnData } = details.data.data;
      yield axios
        .get(returnData.signedRequest, { responseType: 'blob' })
        .then((resp) => resp.data)
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          // for ie and edge
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const returnDecodedUrl = decodeURIComponent(returnData.url);
            var fileName = returnDecodedUrl.substr(
              returnDecodedUrl.lastIndexOf('/') + 1
            );

            window.navigator.msSaveOrOpenBlob(blob, fileName);
            return;
          }

          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          const returnedDecodedUrl = decodeURIComponent(returnData.url);
          a.download = returnedDecodedUrl.substr(
            returnedDecodedUrl.lastIndexOf('/') + 1
          );
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          // error here
        });
    }
  } catch (error) {
    yield put(errorHandler(error));
  }

  yield put(setAgentStatusLoaderFun(false));
}

export function* enoAndW9FileUploadWatcher() {
  yield takeEvery(myAccountActions.ENO_AND_W9_FILES_UPLOAD, enow9FileDownload);
}

export function* updateSubAgentStatus({ payload }) {
  const { producerId, status } = payload;
  try {
    const response = yield axios.post(`api/v1/producer/update-status`, {
      producer_id: producerId,
      status: status,
    });
    if (!!response) {
      const { data } = response;
      data.producer_id = producerId;
      yield put(storeSubAgentStatus(data));
      yield displaySuccessMessage(data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
}

export function* updateSubAgentStatusWatcher() {
  yield takeEvery(myAccountActions.SET_SUB_AGENT_STATUS, updateSubAgentStatus);
}

export function* fetchNiprLicenseList({ payload }) {
  try {
    yield put(updateLicenseOnRefreshLoader(true));
    const response = yield axios.post(
      `api/v1/producer/refresh-licenses`,
      payload
    );
    if (!!response) {
      const { data } = response;
      if (data.success) {
        const { licenseInfo, refreshLicenses } = data;
        yield put(
          updateLicenseOnRefresh({
            licenseInfo,
            refreshResponse: refreshLicenses,
          })
        );
      }
      yield displaySuccessMessage(data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(updateLicenseOnRefreshLoader(false));
}

export function* fetchLicenseListOnRefreshWatcher() {
  yield takeEvery(
    myAccountActions.FETCH_LICENSE_ON_REFRESH,
    fetchNiprLicenseList
  );
}

export function* validateNpnByType({ payload }) {
  try {
    yield put(updateAgentLoader(true));
    const response = yield axios.post(
      `api/v1/producer/validate-npn-by-type `,
      payload
    );
    if (response.data.success) {
      if (!isEmpty(get(response.data, 'validateNPNReponses', null))) {
        yield put(setValidateNiprResponse(response.data.validateNPNReponses));
      }
    } else {
      yield displayErrorMessage(response.data.message);
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(updateAgentLoader(false));
}

export function* validateNpnByTypeWatcher() {
  yield takeEvery(myAccountActions.VALIDATE_NPN_BY_TYPE, validateNpnByType);
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([
    fork(fetchAgentDetailsWatcher),
    fork(saveSettingsWatcher),
    fork(fetchAgentDocumentDetailsWatcher),
    fork(agentDocumentRenameWatcher),
    fork(agentDocumentDeleteWatcher),
    fork(agentDocumentFilesUploadWatcher),
    fork(agentDocumentFolderCreateActionWatcher),
    fork(saveLicenseListWatcher),
    fork(saveUpdateLicenseListWatcher),
    fork(enoAndW9FileUploadWatcher),
    fork(updateSubAgentStatusWatcher),
    fork(fetchLicenseListOnRefreshWatcher),
    fork(validateNpnByTypeWatcher),
    fork(addNewTeamMembersWatcher),
    fork(checkStepUpNecessityWatcher),
  ]);
}
