import * as myAccountActions from '../../constants/action-types';

export const checkStepUpNecessity = () => ({
  type: myAccountActions.CHECK_STEP_UP_NECESSITY,
})

export const showReauthenticateModal = () => ({
  type: myAccountActions.SHOW_REAUTHENITACATE_MODAL,
})

// selected agent details fetch
export const fetchAgentDetails = (producer_id) => ({
  type: myAccountActions.FETCH_AGENT_DETAILS,
  payload: {
    producer_id,
  },
});

export const setSubAgentPromptValue = (flag) => ({
  type: myAccountActions.SET_SUB_AGENT_PROMPT_VALUE,
  payload: flag,
});

export const setAgentDetails = (details) => ({
  type: myAccountActions.SET_AGENT_DETAILS,
  payload: details,
});

// used to show YTD stats for commissions, incentives and total payout on dashboard.
export const setAgentYTDStats = (details) => ({
  type: myAccountActions.SET_AGENT_YTD_STATS,
  payload: details,
});

export const setAgentDetailsLoader = (flag) => ({
  type: myAccountActions.AGENT_DETAILS_LOADER,
  payload: flag,
});

export const dashboardAgentInfoUpdate = (data) => {
  return {
    type: myAccountActions.DASHBOARD_AGENT_INFO_UPDATE,
    payload: data,
  };
};

// get document details from server.
export const fetchAgentDocumentDetails = (producerID) => {
  return {
    type: myAccountActions.FETCH_AGENT_DOCUMENTS_DETAILS,
    payload: producerID,
  };
};

// update the store for stored document.
export const storeDocumentDetails = (data) => ({
  type: myAccountActions.STORE_DOCUMENT_DETAILS,
  payload: data,
});

// update the store for stored document.
export const renameDocumentDetails = (data) => ({
  type: myAccountActions.RENAME_DOCUMENT_DETAILS,
  payload: data,
});

// update the store for stored document.
export const deleteDocumentDetails = (data) => ({
  type: myAccountActions.DELETE_DOCUMENT_DETAILS,
  payload: data,
});

// stored document loader
export const storedDocumentLoader = (flag) => ({
  type: myAccountActions.STORED_DOCUMENT_LOADER,
  payload: flag,
});

// rename document in document tree
export const renameDocument = (payload) => ({
  type: myAccountActions.DOCUMENT_RENAME,
  payload,
});

// delete document in document tree
export const deleteDocument = (payload) => ({
  type: myAccountActions.DOCUMENT_DELETE,
  payload,
});

export const clearDirectoryList = () => ({
  type: myAccountActions.CLEAR_FOLDER_LIST,
});

// save edited agent details to store only.
export const storeAgentDetails = (payload) => ({
  type: myAccountActions.STORE_AGENT_DETAILS,
  payload,
});

export const agentDocumentFilesUpload = (file, folder_name, entity) => {
  return {
    type: myAccountActions.AGENT_DOCUMENT_FILES_UPLOAD,
    payload: { file, folder_name, entity },
  };
};

// Document Folder Create Action
export const agentDocumentFolderCreate = (payload) => {
  return {
    type: myAccountActions.DOCUMENT_FOLDER_CREATE,
    payload,
  };
};

export const agentDocumentFilesUploadDone = (payload) => {
  return {
    type: myAccountActions.AGENT_DOCUMENT_FILES_UPLOAD_DONE,
    payload,
  };
};

export const agentDocumentFilesTreeUpdate = (payload) => {
  return {
    type: myAccountActions.AGENT_DOCUMENT_FILES_TREE_UPDATE,
    payload,
  };
};

export const agentDocumentFilesUploading = (payload) => {
  return {
    type: myAccountActions.AGENT_DOCUMENT_FILES_UPLOADING,
    payload,
  };
};

export const closedModelFun = (payload) => ({
  type: myAccountActions.CLOSED_MODEL,
  payload,
});

// EnO - W9 edit FIle upload
export const enow9FileDownload = (payload) => ({
  type: myAccountActions.ENO_AND_W9_FILES_UPLOAD,
  payload,
});

export const setSubAgentStatus = (flag) => ({
  type: myAccountActions.SET_SUB_AGENT_STATUS,
  payload: flag,
});

export const storeSubAgentStatus = (flag) => ({
  type: myAccountActions.STORE_SUB_AGENT_STATUS,
  payload: flag,
});

export const teamMemberAdded = (flag) => ({
  type: myAccountActions.SUBAGENT_ADDED,
  payload: flag,
});

export const teamMemberEmailDuplicate = (payload) => ({
  type: myAccountActions.SUBAGENT_EMAIL_DUPLICATION,
  payload,
});

export const invalidFileType = (payload) => ({
  type: myAccountActions.INVALID_FILE_TYPE,
  payload,
});

export const storeAgreementData = (flag) => ({
  type: myAccountActions.STORE_AGREEMENT_DATA,
  payload: flag,
});

export const updateNpnData = (payload) => ({
  type: myAccountActions.UPDATE_NPN_DATA,
  payload,
});

// Update License list on nipper call
export const fetchLicenseOnRefresh = (payload) => ({
  type: myAccountActions.FETCH_LICENSE_ON_REFRESH,
  payload,
});

export const updateLicenseOnRefresh = (payload) => ({
  type: myAccountActions.UPDATE_LICENSE_ON_REFRESH,
  payload,
});

export const updateLicenseOnRefreshLoader = (payload) => ({
  type: myAccountActions.UPDATE_LICENSE_ON_REFRESH_LOADER,
  payload,
});

export const updateAgentRole = (payload) => ({
  type: myAccountActions.UPDATE_AGENT_ROLE,
  payload,
});

export const storeComplianceLicense = (payload) => ({
  type: myAccountActions.STORE_COMPIANCE_LICENSE,
  payload,
});

export const responseModal = (payload) => ({
  type: myAccountActions.RESPONSE_MODEL,
  payload,
});

export const validateNpnByType = (data) => {
  return {
    type: myAccountActions.VALIDATE_NPN_BY_TYPE,
    payload: data,
  };
};

export const setValidateNiprResponse = (data) => ({
  type: myAccountActions.SET_VALIDATE_NIPR_RESPONSE,
  payload: data,
});

export const clearRefreshMessage = () => ({
  type: myAccountActions.CLEAR_REFRESH_MESSAGE,
});

// Update agent status reason
export const updateAgentStatusReason = (payload) => ({
  type: myAccountActions.UPDATE_AGENT_STATUS_REASON,
  payload,
});

// Update agent compliance
export const updateAgentCompliance = (payload) => ({
  type: myAccountActions.UPDATE_AGENT_COMPLIANCE,
  payload,
});

// Update agent compliance
export const agentDocumentFileUploadError = (payload) => ({
  type: myAccountActions.AGENT_DOCUMENT_FILE_UPLOAD_ERROR,
  payload,
});
// Update agent compliance
export const clearMyAccountData = () => ({
  type: myAccountActions.CLEAR_DATA,
});

// On adding new Team Members.
export const addNewTeamMembers = (payload) => ({
  type: myAccountActions.ADD_NEW_TEAM_MEMBERS,
  payload,
});

// On Save team member info loader.
export const teamMemberDetailsLoader = (flag) => ({
  type: myAccountActions.TEAM_MEMBER_LOADER,
  payload: flag,
});

export const teamMemberDuplicateEmailHandling = (payload) => ({
  type: myAccountActions.TEAM_MEMBER_DUPLICATE_EMAIL,
  payload,
});

export const duplicatePhoneNumber = (payload) => ({
  type: myAccountActions.DUPLICATE_PHONE_NUMBER,
  payload,
});
