import { all, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'util/api';
import * as documentDownloadAction from 'constants/action-types';
import { documentDownloadLoader } from '../actions/document-download';
import { errorHandler } from '../actions/error';

function* getAgentDocumentPath({ payload }) {
  yield put(documentDownloadLoader(true));

  let details = null;

  try {
    details = yield axios.post('/api/v1/vendor/aws-config', payload);
  } catch (error) {
    // FRC-3321: Disallow for downloading W9 files for the producers
    // Currently, we expect to get the 403 error code from the server
    // when the producer tries to download the W9 file. This might
    // be changed in the future, as it's prone to errors.
    if (error.response && error.response.status === 403) {
      yield put(documentDownloadLoader(false));
      return;
    }
  }

  try {
    if (details.data) {
      const { returnData } = details.data.data;
      yield axios.get(returnData.signedRequest, { responseType: 'blob' })
        .then((resp) => resp.data)
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          // for ie and edge
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            const returnDecodedUrl = decodeURIComponent(returnData.url);
            var fileName = returnDecodedUrl.substr(
              returnDecodedUrl.lastIndexOf('/') + 1
            );

            window.navigator.msSaveOrOpenBlob(blob, fileName);
            return;
          }

          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          const returnedDecodedUrl = decodeURIComponent(returnData.url);
          a.download = returnedDecodedUrl.substr(
            returnedDecodedUrl.lastIndexOf('/') + 1
          );
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(() => {
          // error here
        });
    }
  } catch (error) {
    yield put(errorHandler(error));
  }
  yield put(documentDownloadLoader(false));
}

export function* agentDocumentPathWatcher() {
  yield takeEvery(
    documentDownloadAction.GET_DOCUMENT_PATH,
    getAgentDocumentPath
  );
}

// ------ ROOT SAGA -----------------
export default function* rootSaga() {
  yield all([fork(agentDocumentPathWatcher)]);
}
