// @ts-check

import { anonymize as fullStoryAnonimize } from '@fullstory/browser';

import * as authType from 'constants/action-types';
import {
  CARRIER_TOOLIP_STATE,
  CARRIER_TOOLTIP,
  EO_BANNER_LAST_DISMISSED_AT_KEY,
  EXPERIMENTAL_MODE_KEY,
  FS_CLIENT_VISITOR_KEY,
  FS_DEFAULT_HIT_CACHE_KEY,
  MODAL_DEFERRED_SIGNUP_TIMESTAMP_KEY,
} from 'constants/constant';
import { PWDLESS_SIGNIN_DEFAUlT_SELECTED_TAB_KEY } from 'containers/passwordless-sign-in-form/passwordless-sign-in-form.utils';
import { call, put, select, takeLeading } from 'redux-saga/effects';
import {
  errorHandler,
  setCarrierTooltip,
  setInitUrl,
  setStateInitialized,
  userSignOutSuccess,
} from 'redux/actions';
import { saveState } from 'redux/persist';
import axios from 'util/api';
import { getAuth0SpaClient } from 'util/auth/auth0-spa-client';
import {
  AUTH_FLOW_PASSWORDLESS_ENABLED,
  AUTH_FLOW_PASSWORDLESS_REMEMBER_ME,
} from 'util/auth/auth0.constants';
import { cleanupRedirectCookieData } from 'util/redirect-url';

const FLAGS_TO_PERSIST = [
  EXPERIMENTAL_MODE_KEY,
  PWDLESS_SIGNIN_DEFAUlT_SELECTED_TAB_KEY,
  AUTH_FLOW_PASSWORDLESS_ENABLED,
  AUTH_FLOW_PASSWORDLESS_REMEMBER_ME,
  MODAL_DEFERRED_SIGNUP_TIMESTAMP_KEY,
  EO_BANNER_LAST_DISMISSED_AT_KEY,
  'modalDeferredSignupTimestamp',
  FS_DEFAULT_HIT_CACHE_KEY,
  FS_CLIENT_VISITOR_KEY
];

async function performAuth0Logout(returnTo) {
  const spaClient = getAuth0SpaClient();
  await spaClient.logout({
    logoutParams: { returnTo },
  });
}

function* signOutSaga({ payload }) {
  try {
    const returnTo = payload?.returnTo;
    const keepRedirectCookie = payload?.keepRedirectCookie === true;

    // Get relevant veriables
    let reduxState = yield select();
    const { carrierTooltip: carrierTooltipValue } = reduxState.auth;

    // Clear local storage
    const valuesToPersist = FLAGS_TO_PERSIST.map((key) => ({
      key,
      value: localStorage.getItem(key),
    }));
    sessionStorage.clear();
    localStorage.clear();
    localStorage.setItem('magicToken', 'false');
    if (carrierTooltipValue) {
      localStorage.setItem(CARRIER_TOOLTIP, CARRIER_TOOLIP_STATE.REMOVED);
    }
    for (const { key, value } of valuesToPersist) {
      if (value && value !== 'null') {
        localStorage.setItem(key, value);
      }
    }

    // Clear session storage
    sessionStorage.clear();

    // Teardown third parties
    fullStoryAnonimize();
    window.heap?.resetIdentity();
    window.CoralogixRum?.setUserContext({
      producer_code: undefined,
      producer_email: undefined,
      user_id: undefined,
    });

    // Clear cookies
    if (!keepRedirectCookie) {
      cleanupRedirectCookieData();
    }

    // Clear remaining app data
    delete axios.defaults.headers.common['Authorization'];

    // Update redux state
    yield put(userSignOutSuccess());
    yield put(setStateInitialized(false));
    yield put(setInitUrl('/signin'));
    if (carrierTooltipValue) {
      yield put(setCarrierTooltip(CARRIER_TOOLIP_STATE.REMOVED));
    }
    reduxState = yield select();
    saveState(reduxState); // ensure changes saved before redirect

    // Universal Login flow - sign out with auth0
    const redirectUrl = `${window.location.origin}${returnTo || '/signin'}`;
    yield call(performAuth0Logout, redirectUrl);
  } catch (error) {
    if (!error.response) {
      yield put(errorHandler(error));
    }
  }
}

export function* signOutSagaWatcher() {
  yield takeLeading(authType.SIGNOUT_USER, signOutSaga);
}
