// @ts-check

import 'assets/vendors/style';
import { GlobalAuthLoader } from 'components/global-loader';
import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  setIsGlobalAuthLoaderShown,
  signinAuth0FetchUserData,
} from 'redux/actions/auth';
import { getIsAuthenticatedViaNewApp } from 'util/auth/get-is-authenticated-via-new-app';

// When authenticated via new app, trigger required data fetch & render a blocking loader.
// Logic at this level allows to avoid significant refactor (undesirable due to migration).
const ExternalAuthRequiredDataLoader = ({
  children,
  isGlobalAuthLoaderShown,
  authToken,
}) => {
  const dispatch = useDispatch();
  const isAuthenticatedViaNewApp = getIsAuthenticatedViaNewApp();
  const isRequiredDataFetched = !!authToken;

  useEffect(() => {
    if (!isAuthenticatedViaNewApp) {
      return;
    }

    if (isRequiredDataFetched) {
      dispatch(setIsGlobalAuthLoaderShown(false));
    } else {
      // trigger saga that will fetch data and subsequently hide the global loader
      dispatch(signinAuth0FetchUserData());
    }
  }, []);

  if (!isAuthenticatedViaNewApp) {
    // If not explicitly authenticated via new app - play it safe and render the app as is.
    return children;
  }

  if (isGlobalAuthLoaderShown) {
    return <GlobalAuthLoader />;
  }

  return children;
};

const mapStateToProps = ({ auth }) => {
  const { authToken, isGlobalAuthLoaderShown } = auth;
  return { authToken, isGlobalAuthLoaderShown };
};

export default connect(mapStateToProps)(ExternalAuthRequiredDataLoader);
